import React from 'react'
import { Link, graphql } from 'gatsby'
import Icon from '@mdi/react'
import { mdiClockOutline } from '@mdi/js'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import Button from '../components/button'

class Blog extends React.Component {
  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title='Publicações' />
        <Bio />
        <div style={{ margin: '20px 0 40px' }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug

            return (
              <div key={node.fields.slug}>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4)
                  }}
                >
                  <Link
                    style={{ boxShadow: `none` }}
                    to={node.fields.slug.startsWith("/blog") ? node.fields.slug : `/blog${node.fields.slug}`}
                  >
                    {title}
                  </Link>
                </h3>

                <p
                  style={{
                    display: 'flex',
                    alignContent: 'center'
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: rhythm(0.1)
                    }}
                  >
                    <Icon
                      path={mdiClockOutline}
                      title='Data de Publicação'
                      size={0.7}
                      horizontal
                    />
                  </span>
                  <small>{node.frontmatter.date}</small>
                </p>

                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt
                  }}
                />
              </div>
            )
          })}
        </div>
        <Link to='/'>
          <Button marginTop='85px'>Inicial</Button>
        </Link>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY", locale: "pt")
            title
            description
          }
        }
      }
    }
  }
`
